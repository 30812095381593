import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import FoundationWrapper from "./../../components/FoundationWrapper";
import Selector from "./../../components/Selector";
import { H4BigStrong } from "./../../components/Typography";
import {
  LearningDiv,
  ArticleBodyWrapper,
} from "./../../components/FoundationStyles/elements";

const FoundationLearningPage = () => {
  const learningPageQuery = useStaticQuery(graphql`
    {
      craftFoundationLearningFoundationLearningEntry {
        title
        foundationLearningTabset {
          ... on Craft_foundationLearningTabset_foundationLearningTab_BlockType {
            foundationLearningTabTitle
            foundationLearningTabText
          }
        }
      }
    }
  `).craftFoundationLearningFoundationLearningEntry;
  return (
    <FoundationWrapper title={learningPageQuery.title} selected selection={3}>
      <Selector
        tabs={learningPageQuery.foundationLearningTabset.map((tab, index) => {
          return {
            title: tab.foundationLearningTabTitle,
            content: (
              <ArticleBodyWrapper>
                <LearningDiv>
                  <H4BigStrong>{tab.foundationLearningTabTitle}</H4BigStrong>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: tab.foundationLearningTabText,
                    }}
                  />
                </LearningDiv>
              </ArticleBodyWrapper>
            ),
          };
        })}
        color={"var(--purple)"}
      />
    </FoundationWrapper>
  );
};

export default FoundationLearningPage;
